/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
import { unique } from "@/assets/app.js";
import BzwzcgInfo from "./BzwzcgInfo";
export default {
  props: {
    tremListId: {
      default: []
    }
  },
  components: {
    BzwzcgInfo
  },
  data() {
    return {
      searchValbh: "",
      searchValmc: "",
      showInfo: false,
      ListId: [],
      dataList: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: [],
      itemString: "",
      wpInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
  },
  computed: {
    ListIdNew() {
      this.ListId = [];
      this.tremListId.map(item => {
        this.ListId.push(item.id);
      });
      return this.ListId;
    }
  },
  watch: {
    ListIdNew: function (newid) {
      this.$refs.checkboxGroup.toggleAll();
      this.result = newid;
    }
  },
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var formData = new FormData();
      formData.append("pageIndex", pageNum);
      formData.append("pageSize", this.pageSize);
      if (this.searchValbh != "" || this.searchValmc) {
        let seachVal = [{
          name: "Q_BH_S_LK",
          value: this.searchValbh
        }, {
          name: "Q_MC_S_LK",
          value: this.searchValmc
        }];
        formData.append("filter", JSON.stringify(seachVal));
      }
      var url = _baseUrl + `/bzwzMgmt/basicTabManager/bzWzBasicTab/listData.do`;
      this.$ajax.post(url, formData).then(res => {
        this.dataList = res.data.data;
        this.dataList.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    onClear() {
      this.searchValbh = "";
      this.searchValmc = "";
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    goodsInfo(item) {
      this.showInfo = true;
      this.wpInfoData = item;
    },
    //确定
    onSelects() {
      let result = [];
      this.mount.map(item => {
        this.result.map(id => {
          if (id == item.id) {
            result.push(item);
          }
        });
      });
      this.$emit("cgwpList", result);
    }
  }
};